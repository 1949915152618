import React from "react";
import HighFee from "../components/assets/images/economic-growth.jpg";
import Clock from "../components/assets/images/clock.jpg";
import Reliable from "../components/assets/images/reliable.jpg";

export const About = () => {
  return (
    <>
      <div className="container">
        <div className="heading text-center py-12">
          <h1 className="text-5xl !text-5xl font-semibold text-black">Worried About Your CO2 Emission?</h1>
        </div>
      </div>
      <AboutContent />
    </>
  );
};

export const AboutContent = () => {
  return (
    <section className="w-full px-4 sm:px-8 md:px-12 lg:px-40">
      {/* Grid Layout with Responsive Columns */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Card 1 */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 md:p-6 flex flex-col justify-between">
          <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold mb-2">
            High Fee for a Simple Audit?
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-4">
            For just $5/month, RecyGlo takes care of your CO2 emission, so you don't have to.
          </p>
          <img
            src={HighFee}
            alt="Market street"
            className="rounded-lg mb-2 object-cover h-40 md:h-48 lg:h-56"
          />
        </div>

        {/* Card 2 */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 md:p-6 flex flex-col justify-between">
          <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold mb-2">
            Worried About Waiting Time?
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-4">
            Tired of waiting too long? Let RecyGlo speed things up for you!
          </p>
          <img
            src={Clock}
            alt="Clock"
            className="rounded-lg mb-2 object-cover h-40 md:h-48 lg:h-56"
          />
        </div>

        {/* Card 3 */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 md:p-6 flex flex-col justify-between">
          <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold mb-2">
            Struggling to Find Reliability and Accuracy?
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-600 mb-4">
            Struggling to find reliable and accurate solutions? RecyGlo delivers both!
          </p>
          <img
            src={Reliable}
            alt="Reliability"
            className="rounded-lg mb-2 object-cover h-40 md:h-48 lg:h-56"
          />
        </div>
      </div>
    </section>
  );
};






