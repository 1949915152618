import React from "react";
import { HiOutlineCamera, HiOutlineUserAdd, HiOutlineChartBar } from "react-icons/hi"; // Icons for features

export const Courses = () => {
  return (
    <>
      <section id="meetrecyglo" className="courses bg-[#c6ecce] py-16">
        <div className="w-4/5 m-auto">
          <div className="container">
            <div className="heading text-center py-12">
              <h1 className="text-5xl !text-5xl font-semibold text-black">Meet RecyGlo Innovation!
            </h1>
          </div>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-3 gap-8 md:grid-cols-1">
          {/* Feature 1: Open an Account */}
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center text-center">
            <HiOutlineUserAdd className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Open an Account</h2>
            <p className="text-gray-600">
              Create your free RecyGlo account in just a few steps.
            </p>
          </div>

          {/* Feature 2: Snap a Picture */}
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center text-center">
            <HiOutlineCamera className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Snap a Picture</h2>
            <p className="text-gray-600">
              Capture a picture of your bill or receipt to get started.
            </p>
          </div>

          {/* Feature 3: Get Carbon Emission Result */}
          <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center text-center">
            <HiOutlineChartBar className="text-6xl text-green-600 mb-4" />
            <h2 className="text-2xl font-semibold mb-2">
              Get Your Carbon Emission Result
            </h2>
            <p className="text-gray-600">
              Instantly calculate the carbon impact of your bill.
            </p>
          </div>
        </div>
      </div>
    </section >
    </>
  );
};
