import React from "react";
import partner1 from "../components/assets/images/cmu.jpeg";
import partner2 from "../components/assets/images/beam.png";
import pdf1 from "../components/assets/pdfs/research1.pdf"; // PDF for partner1
import pdf2 from "../components/assets/pdfs/research2.pdf"; // PDF for partner2

export const Partners = () => {
  return (
    <section className="py-16">

      {/* Section Heading */}
      <div className="heading py-12 text-center w-2/3 m-auto md:w-full">
        <h1 className="text-3xl font-semibold text-black">
          <span className="border-b-2 border-black pb-2">Research</span>
        </h1>
      </div>

      {/* Partner Images with Download Links */}
      <div className="flex justify-center items-center gap-16 h-[400px]">
        {/* Image 1 - PDF Download */}
        <a href={pdf1} download>
          <img
            src={partner1}
            alt="Partner 1"
            className="h-96 w-60 shadow-lg rounded-lg cursor-pointer"
          />
        </a>

        {/* Image 2 - PDF Download */}
        <a href={pdf2} download>
          <img
            src={partner2}
            alt="Partner 2"
            className="h-96 w-60 shadow-lg rounded-lg cursor-pointer"
          />
        </a>
      </div>

    </section>
  );
};
