import React, { useState } from "react";
import { addUser } from "../service"; // Import Firebase function
import logo from "../components/assets/images/logo-black.png";
import heroImgback from "../components/assets/images/hero-shape-purple.png";
import mockupImage from "../components/assets/images/mockup.png";
import videoSrc from "../components/assets/videos/wait.mp4";
import { About } from "./About";
import { Courses } from "./Program";
import { Partners } from "./Partners";
import { Offices } from "./Offices";
import { TCenter } from "./TestCenter";

export const Home = () => {
  return (
    <>
      <HomeContent />
      <About />
      <br />
      <br />
      <Courses />
      <TCenter />
      <Partners />
      <Offices />
    </>
  );
};

export const HomeContent = () => {
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    await addUser(formData); // Add user to Firestore
    alert("Your information has been submitted successfully!");
    setFormData({ name: "", email: "", phone: "" }); // Reset form
  };

  return (
    <section className="relative h-screen">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover z-0"
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/90 to-black/50 z-5"></div>

      {/* Navigation Bar */}
      <nav className="absolute top-0 left-0 w-full z-20 flex items-center justify-evenly p-4 px-20">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-10" />
        </div>
        <ul className="flex space-x-20 text-white text-lg">
          <li>
            <a
              href="https://www.recyglo.com"
              className="hover:underline"
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="https://www.recyglo.com/copy-of-recyglo-myanmar"
              className="hover:underline"
            >
              Country
            </a>
          </li>
          <li>
            <a href="https://www.recyglo.com" className="hover:underline">
              Partner
            </a>
          </li>
          <li>
            <a href="#meetrecyglo" className="hover:underline">
              About
            </a>
          </li>
          <li>
            <a href="#office" className="hover:underline">
              Contact Us
            </a>
          </li>
        </ul>
      </nav>

      {/* Content Section */}
      <div className="container relative z-10 h-full flex items-center justify-center">
        <div className="flex items-center justify-center md:flex-col w-full">
          {/* Left Section */}
          <div className="left w-1/2 text-primary md:w-full">
            <h1 className="text-4xl leading-tight font-semibold">
              Recyglo <br /> For Every Business
            </h1>
            <h3 className="text-lg mt-3">
              Snap Your Bill, Track Your Impact – Instantly Calculate Your
              Carbon Footprint!
            </h3>

            {/* Form Section */}
            <form className="mt-8 space-y-4" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 rounded-md border border-white/50 bg-white/10 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 rounded-md border border-white/50 bg-white/10 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-3 rounded-md border border-white/50 bg-white/10 text-white placeholder-white focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <button
                type="submit"
                className="w-full p-3 bg-primary text-white rounded-md hover:bg-blue-700/90 transition-colors"
              >
                Submit
              </button>
            </form>
          </div>

          {/* Right Section - Hidden on Mobile */}
          <div className="mb-96 right right-section w-1/2 md:w-full">
            <div className="images relative">
              <img
                src={heroImgback}
                alt="Background Shape"
                className="absolute top-36 left-28 h-80 w-100 md:left-10 z-10"
              />
              <img
                src={mockupImage}
                alt="Mockup"
                className="absolute top-[-40px] right-20 h-[500px] w-auto md:top-10 md:right-10 z-20 shadow-2xl rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
