// service.jsx
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore"; 

const firebaseConfig = {
  apiKey: "AIzaSyBfEZxChskALDGf-JVo6DND1tOwkTFALfE",
  authDomain: "recyglo-waitlist.firebaseapp.com",
  projectId: "recyglo-waitlist",
  storageBucket: "recyglo-waitlist.appspot.com",
  messagingSenderId: "946440969801",
  appId: "1:946440969801:web:fa0ba61086e98802212998"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Firestore functions to add data
export const addUser = async (user) => {
  try {
    const docRef = await addDoc(collection(db, "users"), user);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
