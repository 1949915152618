import React from "react"
import { FaFacebook, FaLinkedin } from "react-icons/fa"
import logImg from "../assets/images/logo-black.png"

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section className='app w-4/5 m-auto rounded-lg shadow-shadow2 text-white flex md:flex-col bg-primary
       mt-16 relative z-10'>
        <div className='left w-[60%] md:w-full p-10'>
          <h1 className='text-4xl font-semibold leading-tight'>
            GET IN TOUCH
          </h1>
          <span className='text-[14px]'>Office: Ground Floor, The Offices At Central World, 999/9 ถ. พระรามที่ 1 แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330</span><br />
          <span className='text-[14px]'>Email: contact@recyglo.com</span><br />
          <span className='text-[14px]'>Phone: +66(0)950813901</span><br />
          <span className='text-[14px]'>Follow us: <br />
            <div className="flex">
              <a className="mr-2" href="https://web.facebook.com/recyglo.th" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} color="white" />
              </a>
              <a href="https://www.linkedin.com/company/recyglo-thailand" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} color="white" />
              </a>
            </div>
          </span>
          <div className='right w-[40%] md:w-full flex items-center px-5 rounded-r-lg rounded-bl-[500px] gap-8 bg-[#FF7C54] md:bg-transparent md:p-8'>
          </div>
        </div>
      </section>
      <footer className='bg-[#F3F4F8] py-10 pt-32 -mt-24'>
        <div className='container grid grid-cols-3 gap-5 md:grid-cols-2'>
          <p className="copyright">
            <img src={logImg} alt="logImg" className="h-5" />© {currentYear} Recyglo Thailand Co., All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  )
}
