import React from "react";
import { NavLink } from "react-router-dom";
import { tcenter } from "../components/assets/data/dummydata";

export const TCenter = () => {
  return (
    <div className="w-full bg-black py-16 px-4">
      <div className="w-full py-16 text-white px-3">
        <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
          <div className="lg:col-span-2 my-4">
            <h1 className="text-primary md:text-4xl sm:text-3xl text-2xl font-bold py-2">
              Want to know more about RecyGlo?
            </h1>
            <p>
              <b>Vision: </b> To become a leading waste management solution platform in Southeast Asia that produces zero waste and zero carbon footprint.
            </p>
            <br />
            <p>
              <b>Mission: </b> To process material in a safe, non-hazardous manner with an aim to keep the world environmentally clean.
            </p>
            <br />
            <p>
              <b>Purpose:</b> Introduce a waste management system in Southeast Asia to develop proper waste management and recycling culture.
            </p>
            <br />
          </div>

          <div className="my-4">
            <div className="flex flex-col sm:flex-row items-center justify-between w-full">
              <NavLink
                to="https://recyglo.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3">
                  recyglo.com
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};
