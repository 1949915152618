export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 3,
    title: "CIIE",
    url: "/courses",
  },
  {
    id: 4,
    title: "Test Center",
    url: "/testcenter",
  },
  {
    id: 4,
    title: "Vocational Education",
    url: "/vocationaleducation/overview",
  },
  {
    id: 5,
    title: "Offices",
    url: "/offices",
  },
]
export const LinkData2 = [
  {
    id: 1,
    title: "Overview",
    url: "/vocationaleducation/overview",
  },
  {
    id: 2,
    title: "About JRV",
    url: "/vocationaleducation/about",
  },
  {
    id: 3,
    title: "Program",
    url: "/vocationaleducation/program",
  },
  {
    id: 4,
    title: "Campus Life",
    url: "/vocationaleducation/campuslife",
  },
  {
    id: 4,
    title: "Apply Now",
    url: "/vocationaleducation/apply",
  },
]
export const courses = [
  {
    id: 1,
    cover: "../images/ciie.png",
    title: "Innovation & Entrepreneurship",
  },
]
export const testcenter = [
  {
    id: 1,
    cover: "../images/ged.png",
    title: "Pathway to Higher Education",
  },
]
export const tcenter = [
  {
    id: 1,
    cover: "../images/ged.png",
  }, 
  {
    id: 2,
    cover: "../images/ranong_office.jpeg",
  },
]
export const jrv = [
  {imgpath: "../images/jrvlogo.jpg"}
]
export const offices=[
  {
    name: 'Bangkok',
    address: 'Ground Floor, The Offices At Central World, 999/9 ถ. พระรามที่ 1 แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330',
    mapsLink: 'https://maps.app.goo.gl/X1z2MA8h5AuYF9UC6',
    photo: '../images/recyglo-thailand.jpg',
  },
  {
    name: 'Yangon',
    address: 'No, 29, 30B Lan Thit St, Yangon',
    mapsLink: 'https://maps.app.goo.gl/AD65TWmLcD9zrU8M9',
    photo: '../images/recyglo-yangon.jpg',
  },
  {
    name: 'Hanoi',
    address: '8 P. Tràng Thi, Hàng Trống, Hoàn Kiếm, Hà Nội 100000',
    mapsLink: 'https://maps.app.goo.gl/p9jqr63ffqVCpW4t8',
    photo: '../images/recyglo-vietnum.jpg',
  },
]
export const overview = [
  {
    id: 1,
    cover: "../images/jrvlogo.jpg",
  }, 
  {
    id: 2,
    cover: "../images/logo-black.png",
  }, 
  {
    id: 3,
    cover: "../images/infographic_1.jpg",
  }
]
export const campus = [
  {
    photo: '../images/campus_1.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_2.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_3.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_4.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_5.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_6.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_7.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_8.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_9.jpg',
    description: 'short description'
  },
  {
    photo: '../images/campus_10.jpg',
    description: 'short description'
  },
]