import React from "react";
import { offices } from "../components/assets/data/dummydata";

export const Offices = () => {
  return (
    <>
      <section id="office" className="offices">
        <div className="w-4/5 m-auto">
          {/* Section Heading */}
          <div className="heading text-center py-12">
            <h1 className="text-3xl font-semibold text-black">
              <span className="border-b-2 border-black pb-2">Our Offices</span>
            </h1>
          </div>

          {/* Grid of Offices */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {offices.map((office, index) => (
              <div key={index} className="office bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="office-image-wrapper h-64">
                  <img
                    src={office.photo}
                    alt={`Office ${index + 1}`}
                    className="office-image w-full h-full object-cover"
                  />
                  <div className="image-overlay"></div>
                </div>

                <div className="office-info p-4">
                  <h2 className="text-xl font-semibold mb-2">{office.name}</h2>
                  <p className="text-gray-600 mb-2">{office.address}</p>
                  <a
                    href={office.mapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    View on Google Maps
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
